import {
  acpDebitCardTransfersManageComponent,
  acpDebitCardTransfersManageTemplate
} from 'apps/debit-card-transfers/components/debit-card-transfers';
import { AcpDebitCardTransfersSectionComponentCtrl } from './index';

/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpDebitCardTransfersStates($stateProvider) {
  'ngInject';

  $stateProvider
    .state('transfer-funds', {
      parent: 'root',
      url: '/transfer-funds',
      controller: AcpDebitCardTransfersSectionComponentCtrl,
      template:
        '<acp-debit-card-transfers-section></acp-debit-card-transfers-section>',
      data: {
        permissions: {
          only: 'isDebitCardTransfersEligible'
        }
      },
      resolve: {
        module($ocLazyLoad) {
          'ngInject';

          return import(/* webpackChunkName: "acp.section.debit-card-transfers" */ './index').then(
            $ocLazyLoad.loadModule
          );
        }
      }
    })
    .state('transfer-funds.manage', {
      url: '/manage',
      controller: acpDebitCardTransfersManageComponent,
      template: acpDebitCardTransfersManageTemplate
    });
}
